@font-face {
    font-family: 'Aharoni';
    src: local('Aharoni'), url('/public/fonts/Aharoni.ttf');
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('/public/fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Spartan';
    src: local('Spartan'), url('/public/fonts/LeagueSpartan-Bold.ttf');
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url('/public/fonts/DMSans_18pt-Bold.ttf');
}

/* Fonts */
.aharoni {
    font-family: 'Aharoni';
}

.montserrat {
    font-family: 'Montserrat';
}

.spartan {
    font-family: 'Spartan';
}

.sans {
    font-family: 'DMSans';
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Text */

.custom-title {
    font-size: calc(2.5vh + 2.5vw);
}

.shadow-text {
    text-shadow: 2px 2px 2px black;
}

.yellow {
    color: #fff42c
}

.white {
    color: #ffffff
}

.blue {
    color: #2c66ff;
}

.violet {
    color: #a560fe;
}

/* Rotated images */
.diagonal {
    position: relative;
    overflow: hidden;
}

.diagonal img {
    transform-origin: 0 0;
    border: 7px solid #fff;
}

.left img {
    transform: rotate(-2deg);
}

.right img {
    transform: rotate(2deg);
}

/* Images in Circle */
.circle {
    border-radius: 50%;
    background-color: white;
}

.circle img {
    width: 65%;
    height: auto;
    margin-top: 20%;
}

.custom-carousel {
    width: 90vw
}

@media (max-width: 768px) {

    /* Mobile */
    .custom-text {
        font-size: calc(1.4vh + 1.4vw);
    }

    .small-text {
        font-size: calc(1.2vh + 1.2vw);
    }

    .diagonal img {
        width: 75%;
        height: auto;
    }

    .circle {
        width: 10vh;
        height: 10vh;
    }

    .popupLogo {
        width: 4vh !important;
    }
}

@media (min-width: 769px) and (max-width: 1023px) {

    /* Tablet */
    .custom-text {
        font-size: calc(1.4vh + 1.4vw);
    }

    .small-text {
        font-size: calc(1.2vh + 1.2vw);
    }

    .diagonal img {
        width: 55%;
        height: auto;
    }

    .circle {
        width: 15vh;
        height: 15vh;
    }
}

@media (min-width: 1024px) {

    /* Desktop */
    .custom-text {
        font-size: calc(1vh + 1vw);
    }

    .small-text {
        font-size: calc(0.8vh + 0.8vw);
    }

    .diagonal img {
        width: 75%;
        height: auto;
    }

    .circle {
        width: 25vh;
        height: 25vh;
    }
}

.small-circle {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #8c3e7c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.popupLogo {
    z-index: 0;
    position: absolute;
    width: 4vw;
    animation: opacityFadeIn 5s;
    opacity: 0.8;
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.8;
    }
}

/* LogoScroller.css */
.logo-scrollp-scroller {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.logo-scrollp-container {
    display: inline-block;
    animation: scrollscrollp 130s linear infinite;
}

.logo-scrollp {
    height: 50px;
    margin: 0 20px;
    filter: grayscale(100%); 
}

.logo-scrollp:hover {
    filter: grayscale(0%); 
}

@keyframes scrollscrollp {
    0% {
        transform: translateX(.7%);
    }

    100% {
        transform: translateX(-50%);
    }
}