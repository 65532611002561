.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6cede;
  color: black;
  width: 30px; /* 1.5vw / 2.5vh */
  height: 30px; /* 1.5vw / 2.5vh */
  border-radius: 50%;
  position: relative;
  z-index: 1;
  font-size: 14px;
}

.step.active {
  background-color: #8c3e7c;
  color: white;
}

.line {
  height: 3px;
  flex-grow: 1;
  background-color: #e6cede;
  position: relative;
  top: 0px;
  z-index: 0;
}

.line.completed {
  background-color: #8c3e7c;
}