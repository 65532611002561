.blogCard {
    cursor: pointer;
}
.blogCard:hover {
    background-color: rgba(182, 78, 161, 0.05);
    box-shadow: 0px 0px 30px 0px rgba(140, 62, 124, 0.5);
}

.blogTitle {
    font-size: 3vw;
    text-align: center;
    color: #8c3e7c;
}

.blogContent {
    width: 60vw;
    margin: auto;
}

.blogContent h2{
    font-size: 2.1vw;
    font-weight: 400;
    text-align: left;
}
.blogContent p{
    font-size: 1.65vw;
    font-weight: 350;
    text-align: left;
}

.blogContent img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 10%;
}

.blogContent iframe{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 10%;
    border: 5px solid #8c3e7c;
    padding: 1%;
    width: 55vw;
    height: 30vw;
}

.blogFooter {
    font-size: 1.5vw;
    text-align: center;
}

@media (max-width: 800px) { /* Mobile */
    .blogContent {
        width: 90vw !important;
        margin: auto !important;
    }
    .blogTitle {
        font-size: 4.5vh !important;
    }
    .blogContent img{
        margin-bottom: 20% !important;
    }
    .blogContent h2{
        font-size: 2.8vh !important;
    }
    .blogContent p{
        font-size: 2.2vh !important;
    }
    .blogFooter {
        font-size: 2vh !important;
    }
    .blogContent iframe {
        width: 90vw !important;
        height: 55vw !important;
    }
}