/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 4rem;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #8c3e7c;
}

input:focus+.slider {
  box-shadow: 0 0 1px #8c3e7c;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.priceCard {
  border-radius: 20px;
  background: rgb(224, 209, 222);
}

@media (max-width: 800px) { /* Mobile */
  .priceCard {
    width: 90% !important;
    margin: auto !important;
  }
  .textCard {
    font-size: 8vw !important;
  }
}

.textCard {
  color: rgb(140, 62, 124);
  padding-left: 10%;
  font-size: 2vw;
}

.additext {
  line-height: 10px !important;
  height: 20px !important;
}

@keyframes bfglow {
  from {
    box-shadow: 0 10px 30px rgba(255, 82, 82, 0.4);
  }
  to {
    box-shadow: 0 20px 40px rgba(255, 82, 82, 0.8);
  }
}

@keyframes bftglow {
  from {
    text-shadow: 0 5px 10px rgba(255, 82, 82, 0.6);
  }
  to {
    text-shadow: 0 10px 20px rgba(255, 82, 82, 0.8);
  }
}

.cloud-span {
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  background-color: #E3D0DF; /* Your secondary theme color */
  color: #8c3e7c; /* Your primary theme color */
  border-radius: 50px;
  position: relative;
  box-shadow: 0 0 0 3px #8c3e7c; /* Outer border */
  margin: auto;
  font-weight: 650;
}

.cloud-span::before,
.cloud-span::after {
  content: "";
  position: absolute;
  background-color: inherit;
  box-shadow: inherit;
  z-index: -1;
}

.cloud-span::before {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -15px;
  left: 10%;
}

.cloud-span::after {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -15px;
  right: 10%;
}