@font-face {
    font-family: 'Aharoni';
    src: local('Aharoni'), url('/public/fonts/Aharoni.ttf');
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('/public/fonts/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'Spartan';
    src: local('Spartan'), url('/public/fonts/LeagueSpartan-Bold.ttf');
}
@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url('/public/fonts/DMSans_18pt-Bold.ttf');
}

/* Fonts */
.aharoni {
    font-family: 'Aharoni';
}
.montserrat {
    font-family: 'Montserrat';
}
.spartan {
    font-family: 'Spartan';
}
.sans {
    font-family: 'DMSans';
}

.center {
    display: flex;
    justify-content: center;
    align-items: center; 
}

/* Text */

.custom-title {
    font-size: calc(2.5vh + 2.5vw);
}
.shadow-text {
    text-shadow: 2px 2px 2px black;
}
.yellow {
    color: #fff42c
}
.white {
    color: #ffffff
}
.blue {
    color: #2c66ff;
}
.violet {
    color: #a560fe;
}

/* Rotated images */
.diagonal {
    position: relative;
    overflow: hidden;
}
.diagonal img {
    transform-origin: 0 0;
    border: 7px solid #fff;
}
.left img {
    transform: rotate(-2deg);
}
.right img {
    transform: rotate(2deg);
}

/* Images in Circle */
.circle {
    border-radius: 50%;
    background-color: white;
}

.circle img {
    width: 65%;
    height: auto;
    margin-top: 20%;
}
.custom-carousel {
    width: 90vw
}

@media (max-width: 768px) { /* Mobile */
    .column {
        margin-top: 2vh;
    }
    .custom-text {
        font-size: calc(1.4vh + 1.4vw);
    }
    .small-text {
        font-size: calc(1.2vh + 1.2vw);
    }
    .diagonal img {
        width: 75%;
        height: auto;
    }
    .circle {
        width: 10vh;
        height: 10vh;
    }
    .popupLogo {
        width: 4vh !important;
    }
}
@media (min-width: 769px) and (max-width: 1023px) { /* Tablet */
    .column {
        margin-top: 2vh;
    }
    .custom-text {
        font-size: calc(1.4vh + 1.4vw);
    }
    .small-text {
        font-size: calc(1.2vh + 1.2vw);
    }
    .diagonal img {
        width: 55%;
        height: auto;
    }
    .circle {
        width: 15vh;
        height: 15vh;
    }
}
@media (min-width: 1024px) { /* Desktop */
    .custom-text {
        font-size: calc(1vh + 1vw);
    }
    .small-text {
        font-size: calc(0.8vh + 0.8vw);
    }
    .diagonal img {
        width: 75%;
        height: auto;
    }
    .circle {
        width: 25vh;
        height: 25vh;
    }
}

.small-circle {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #8c3e7c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.popupLogo {
    z-index: 0;
    position: absolute;
    width: 4vw;
    animation: opacityFadeIn 5s;
    opacity: 0.8;
}

@keyframes opacityFadeIn {
    0% { opacity: 0; }
    100% { opacity: 0.8; }
}

.dashboard-block {
    background: linear-gradient(
        90deg, 
        rgb(248, 222, 240) 1%, 
        #e6cede 20%);
    border-radius: 20px;
    padding: calc(0.5vh + 0.5vw);
    color: #8c3e7c;
    height: 24.5vh;
    position: relative;
}
.dashboard-block .heading {
    font-size: 1.25vh;
    height: 50%;
    font-weight: 600;
}
.dashboard-block .bigheading {
    height: auto !important;
}
.dashboard-block .bigtitle {
    font-size: 3vh;
    cursor: pointer;
}
.dashboard-block .smalltitle {
    font-size: 2.25vh;
}
.dashboard-block .datanum {
    font-size: 2.8vh;
}
.dashboard-block .data-block:not(:last-child) {
    margin-right: 5%;
}
.dashboard-block .data-container {
    justify-content: center;
    padding: 1% 5%;
    position: absolute;
    bottom: 5vh;
    width: 96%;
}
.dashboard-block .block-icon {
    float: right;
    font-size: 4.5vh;
    max-width: 11%;
    padding: 1%;
    color: #8c3e7c;
}
.iconbutton {
    font-size: 1.75vh;
    color:#8c3e7c;
}

.dashboard-block .gotobutton {
    background-color: #e7dee4;
    color: #8B3C7E;
    font-weight: 700;
    font-size: 1.9vh;
    padding: 0 1vw !important;
    border-color: #8B3C7E;
    margin-top: 1vh;
    position: absolute;
    bottom: 2vh;
}
.dashboard-block .gotobutton:hover {
    background-color: #eee6eb;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}
.dashboard-block .gotobuttonsmall {
    background-color: #e7dee4;
    color: #8B3C7E;
    font-weight: 700;
    font-size: 1.5vh;
    padding: 0 2%;
    margin-top: 1vh;
    height: auto;
    border-color: #8B3C7E;
    position: absolute;
}
.dashboard-block .gotobuttonsmall:hover {
    background-color: #eee6eb;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}
.dashboard-block .gotobuttonmore {
    background-color: #e7dee4;
    color: #8B3C7E;
    font-weight: 700;
    font-size: 1.9vh;
    padding: 0 1vw !important;
    border-color: #8B3C7E;
    margin-top: 1vh;
}
.dashboard-block .gotobuttonmore:hover {
    background-color: #eee6eb;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}

.title .tooltiptext {
    width: auto;
}


@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-25%);
    }
  }
  
.upDown {
animation: moveUpDown 2s ease-in-out infinite;
}