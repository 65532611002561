@media (max-width: 768px) { /* Mobile */
    .card-width {
        width: 80vw
    }
}
@media (min-width: 769px) and (max-width: 1023px) { /* Tablet */
    .card-width {
        width: 40vw
    }
}
@media (min-width: 1024px) { /* Desktop */
    .card-width {
        width: 20vw;
    }
}

.defFont {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.firstCard {
    animation: swipeHint 2s linear infinite;
}

@keyframes swipeHint {
    0%   {transform: translateX(0px);}
    50%  {transform: translateX(-5px); box-shadow: 15px 15px 15px 5px gray;}
    100% {transform: translateX(0px);}
  }