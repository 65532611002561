@keyframes pacman1 {
    0% {transform: rotate(45deg);}
    100% {transform: rotate(0deg);}
}

@keyframes pacman2 {
    0% {transform: rotate(-45deg);}
    100% {transform: rotate(0deg);}
}

@keyframes pacman3 {
    0% { box-shadow: 100px 65px 0 0 #8c3e7c, 160px 65px 0 0 #8c3e7c, 220px 65px 0 0 #8c3e7c, 400px 245px 0 0 transparent; }
    100% { box-shadow: 40px 65px 0 0 #8c3e7c, 100px 65px 0 0 #8c3e7c, 160px 65px 0 0 #8c3e7c; }
}

pac-man {
    display: block;
    margin-top: -20vh;
    margin-left: -5vw;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation: pacman3 1.4s linear 0s infinite;
}
pac-man::before,
pac-man::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -5vw;
    border-radius: 50%;    
    border: 80px solid #e6cede;
    border-right-color: transparent;    
}
pac-man::before {
    animation: pacman1 0.7s linear 0s infinite alternate;
}
pac-man::after {
    animation: pacman2 0.7s linear 0s infinite alternate;
}