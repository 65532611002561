.loaderTitle { 
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
  color: rgba(140,62,124,0.7);
}

.loaderTitle:hover {
  color: rgb(140,62,124);
}

.statisticCardNepeto {
  color: white;
  background-color: rgba(140,62,124,0.7);
}
.statisticCardNepeto:hover {
  background-color: rgb(140,62,124);
}

.statisticCardMarter {
  background-color: rgba(230,175,10,0.7);
}
.statisticCardMarter:hover {
  background-color: rgb(230,175,10);
}

.statisticsCancel {
  background-color: rgba(220,53,69,0.7);
}
.statisticsCancel:hover {
  background-color: rgb(220,53,69);
}

.statisticsInTrial {
  background-color: rgba(135,206,250,0.7);
}
.statisticsInTrial:hover {
  background-color: rgb(135,206,250);
}