.chat-message {
	position: relative;
  }
  
  .chat-body::before {
	content: '';
	position: absolute;
	bottom: 3px;
	left: -10px; /* Adjust as needed */
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 20px 15px 0;
	border-color: transparent #fff transparent transparent; /* Change color as needed */
  }

article {
	border-radius: 10px !important;
}
.message-header {
	border-radius: 10px 10px 0px 0px !important;
}