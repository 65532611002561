.products::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.products {
    overflow-y: hidden;
}

.products-table-container {
    /* height: 72vh; */
    margin-top: 1vh;
    /* overflow-y: scroll; */
}

.products-table {
    color: #8B3C7E !important;
    font-weight: 600;
    width: 88vw;
    font-size: .95vw;
    border-collapse: separate;
    margin: auto;
    /* background-color: rgba(247,246,246, 0.2); */
}
.products-table th {
    border-color: #E3D0DF;
    border-width: 5px 0px 5px 0px !important;
    background-color: rgb(247,246,246);
    color: #8B3C7E !important;
    font-weight: 700;
    top: 0px;
    text-align: center !important;
    position: relative;
}

.products-table th:nth-child(1) {
    text-align: left !important;
  }

.products-table td {
    border-color: #E3D0DF;
    border-width: 0px 0px 5px 0px !important;
    vertical-align: middle;
    text-align: center !important;
    border-bottom-color: #e3d0df4d;
}

.adv-data-content-load {
    border-color: #E3D0DF;
    border-width: 0px 0px 5px 0px !important;
    text-align: center !important;
    width: 100%;
    height: 100%;
    background-image: url("/public/assets/advanced_data_spoiler.png");
    filter: blur(1px);
    opacity: 0.5;
    -webkit-filter: blur(1px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.adv-data-content-text {
    position: absolute;
    left: 33.25%;
    margin-top: -2.6%;
    transform: translate(0%, -20%);
    z-index: 2;
    text-align: center;
    background-color: #e3d0dfe8;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px #61585f;
  }
  .adv-data-content-text:hover {
    box-shadow: 0px 0px 5px 0px black;
  }

.products-table .bg {
    background-color: rgba(0,200,0,0.05);
}

.products-table td img:hover {
    transform: scale(2.5);
    box-shadow: 1px 1px 15px 1px gray;
    background-color: white;
    cursor: pointer;
}

.products-table abbr {
    cursor: pointer;
}

.graphiconhover {
    transform: scaleX(17) scaleY(9) translateX(-0.95vw) translateY(1.5vh) !important;
    border-radius: 0px !important;
    border: none !important;
    z-index: 999;
}

.lightbutton {
    background-color: #E3D0DF;
    color: #8B3C7E;
    font-weight: 700;
    font-size: .95vw;
    padding: 0 1vw !important;
    width: 100%;
}
.lightbutton:hover,
.lightbutton:active,
.lightbutton:focus {
    color: #8B3C7E;
}
.darkbutton {
    background-color: #8B3C7E;
    color: white;
    font-weight: 700;
    font-size: .95vw;
    padding: 0 1vw !important;
}
.darkbutton:hover,
.darkbutton:active,
.darkbutton:focus {
    color: white;
}

@media (max-width: 700px) { /* Mobile */
    .darkbutton,
    .lightbutton {
        font-size: 1.9vh;
    }
    .darkbutton {
        padding: 0 5vw !important;
    }
    .column {
        padding-top: 0;
        padding-bottom: 1vh;
    }
}

.searchproduct::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
.searchproduct:-ms-input-placeholder { /* Edge 12 -18 */
    color: white;
}

.selectlight {
    background-color: #E3D0DF !important;
    color: #8B3C7E !important;
    font-weight: 700 !important;
    padding: 0 1vw !important;
}
.selectlight:hover,
.selectlight:active,
.selectlight:focus {
    color: #8B3C7E !important;
}
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: transparent;
}

.graphicon:not(.graphiconhover):hover {
    transform: scale(1) !important;
    box-shadow: 0px 0px 5px 0px rgb(160, 160, 160) !important;
}

.progress {
    width: 50vw;
    margin: auto;
}
.progress:indeterminate {
    background-image: linear-gradient(to right, #8B3C7E 30%, #ededed 30%)
}

.keepagraphoos:hover {
    z-index: 999
}

.hoverscaleadv:hover {
    transform: scale(1.25) !important;
}

.nohoverscaleadv:hover {
    transform: scale(1) !important;
    box-shadow: none !important;
}

.overlay {
    position: absolute; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 10%;
    transition: .5s ease;
    margin-top: -15px;
    color: white;
    text-align: center;
  }
  
.sourceImage:hover .overlay {
opacity: 0;
}

.bigHover:hover {
    transform: scale(1.1);
}


.sourceImage {
    --warvis: 0;
}
.sourceImage:hover {
    --warvis: 1 !important;
}
.warningSign {
    position: absolute;
    margin-left: 3.75%;
    margin-top: -1%;
    z-index: 10;
    background-color: rgba(255,255,255,var(--warvis));
    border-radius: 5px;
}
.warText {
    font-size: 75%;
}

.hoverImageTable:hover {
    z-index: 9 !important;
}

@keyframes backgroundBlinkAnimation {
    0%, 100% {
        background-color: none;
    }
    50% {
        background-color: #E3D0DF;
        border: 5px solid #8B3C7E;
    }
}

.advDataBtnBlink {
    animation: backgroundBlinkAnimation 2s infinite 0.5s !important;
}


@keyframes shadowAnimation {
    0%, 100% {
        box-shadow: none;
    }
    50% {
        box-shadow: 0 0 10px purple;
    }
}

.actions .graphicon {
    animation: shadowAnimation 2s 5 0.5s; /* Adjust the animation duration and delay as needed */
}
.hidebtnglow {
    animation: shadowAnimation 2s 5 0.5s; /* Adjust the animation duration and delay as needed */
}

/* CHECKBOX SELECT */
.selectdropdown {
    padding: 1vh 1vw !important;
    border-radius: 30px;
    overflow: hidden;
    white-space: nowrap;
}
.selectdropdown fieldset {
    border: none;
}
.selectdropdown fieldset > label {
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
    font-size: 1rem;
    line-height: 28px;
}
.selectdropdown fieldset > label::after {
    content: ":";
}
.selectdropdown select,
.selectdropdown details {
    display: inline-block;
    cursor: pointer;
}
.selectdropdown select,
.selectdropdown summary {
    border: 1px solid var(--lt-gray);
    border-collapse: collapse;
    border-radius: 4px;
    cursor: pointer;
}
.selectdropdown details[open] > .selectdropdown summary::marker {
    color: var(--blue);
}

.selectdropdown select:focus,
.selectdropdown summary:focus,
.selectdropdown summary:active {
    box-shadow: 0 0 5px 1px var(--blue);
}

.selectdropdown ul {
    list-style: none;
    padding: 5px;
    margin-top: 7px !important;
    border: 1px solid var(--dk-gray);
    box-shadow: 0 0 5px 1px var(--lt-gray);
    position: absolute;
    z-index: 99;
    background-color:#E3D0DF;
    width: calc(5vw + 10vh);
    margin-left: -2.8vw;
    text-align: left;
    padding-left: 0.5vw;
    border-radius: 10px;
}
.selectdropdownsupp ul {
    margin-left: 0vw !important;
    width: calc(8vw + 10vh);
    height: 50%;
    overflow-y: scroll;
}
.selectdropdown li {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
}
.selectdropdown li:hover {
    overflow:visible;
}
.selectdropdown li > .selectdropdown label {
    cursor: pointer;
    display: inline-block;
    width: 100%;
}
.selectdropdown li > .selectdropdown label:hover,
.selectdropdown li > .selectdropdown label:has(input:checked) {
    background-color: var(--dk-gray);
}


.pieChart {
    z-index: -1 !important;
    margin-left: -10% !important;
    width: 120% !important;
}
.pieChart:hover {
    z-index: 2 !important;
    position: relative;
}

.blurImg{
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.transparent-white {
    mix-blend-mode: multiply;
}

.floatingAccept {
    position: sticky;
    top: 90vh;
    z-index: 999;
    width: 30vw;
    margin: auto;
    justify-content: center;
    height: 3vh;
    margin-bottom: -3vh;
    display: flex;
    flex-direction: row;
}

.gotobuttonsmall {
    background-color: #e7dee4;
    color: #8B3C7E;
    font-weight: 700;
    font-size: 1.5vh;
    padding: 0 2% .25%;
    margin-top: 0.6vh;
    height: auto;
    border-color: #8B3C7E;
}
.gotobuttonsmall:hover {
    border-color: #8B3C7E;
    color: #8B3C7E;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.variGif:hover {
    transform: scale(1.1);
}

@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.beat-animation {
    animation: beat 0.3s ease-in-out;
}


.keepagraphprd:hover {
    transform: scale(7.5) !important;
    z-index: 999;
}